@media (max-width: 1023px) {
	[data-ui-view] {
		/*display: none !important;*/
	}
	.toosmall {
		display: block;
	}
}
@media (min-width: 1024px) {
	[data-ui-view] {
		/*display: block !important;*/
	}
	.toosmall {
		display: none;
	}
}

#tile-textbox {
	display: flex !important;
}
