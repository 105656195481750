// global styles Wiederanlage!
@darkFontColor: #676a6c;
@chartColor1: #96b8df;

.ct-series-a .ct-bar {
  stroke: @chartColor1;
  stroke-width: 20px;
}

.bro-chart {
  height: 400px;
  width: 400px;
  min-height: 300px;
  min-width: 400px;
}

.ct-chart-pie {
  height: 400px;
  width: 400px;
  min-height: 300px;
  min-width: 300px;
}

chartist {
  height: 222px;
  width: 222px;
}

.ct-chart {
  height: 100%;
  width: 100%;
}

.ct-label {
  font-size: 0.9rem;
  color: @darkFontColor;
}

/*
.ct-slice-pie {
  fill: @darkFontColor;
}

.ct-series-a {
  .ct-area {
    fill:@darkFontColor;
  }
  .ct-slice-pie {
    fill:@darkFontColor;
  }
}
*/
.onoffswitch-inner:after {
  content: "Nein";
  padding-right: 7px;
  background-color: #FFFFFF;
  color: #667899;
  text-align: right;
}

.onoffswitch-inner:before {
  content: "Ja";
  padding-left: 7px;
  background-color: #667899;
  color: #FFFFFF;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #667899;
  border-radius: 3px;
}

.onoffswitch-switch {
  border: 2px solid #667899;
}