//Animate css
@import "animate.less";
@import "broFont/broFont.less";
//Font awesome
@import "../../node_modules/font-awesome/less/font-awesome.less";
//Bootstrap
@import "../../node_modules/bootstrap/less/bootstrap.less";
@import "ionRangeSlider/rangeSlider.less";
@import "ionRangeSlider/rangeSliderSkinFlat.less";
//angular-toastr
@import "toastr.less";
//Main Inspinia Files
@import "inspinia/style.less";
//nouislider //  Didnt work!!! // I'll fix this later
// @import "../../bower_components/nouislider/jquery.nouislider.css";
@import "chartist.less";

// own design
@import "bro.less";
@import "reinvester.less";
@import "iCheck/icheck.less";
@import "checkboxes.less";
@import "customCheckboxes.less";
@import "bootstrapWizard.less";
@import "minscreen.less";

// @import "customColorGP.less"; // comment out for basic template (actually same styles as ../themes/goingPublic.less)
// @import "../themes/goingPublic.less"; //replaces customColorGP.less


.default-tabs {
  border-left: 0 !important;
  background: #ffffff !important;
}

.default-tabs > li {
  border-bottom: 1px solid #f3f3f4 !important;
}

.default-tabs > a:hover {
  background-color: lightgray !important;

}

.default-tabs > a {
  color: #555555 !important;
}

.navbar-default {
  height: 900px;
}

.checkbox label:after {
  padding-left: 4px;
  padding-top: 2px;
  font-size: 9px;
}

span.active {
    background-color: #006c8c;
}

.animated-content {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.label {
  cursor: pointer;
}

.bro-tile-area {
  height: 1200px;
  background: #ffffff;

}

// noIUslider // chartist // CSS

// got a 404 when loading /bower_components/nouislider/jquery.nouislider.css
// in order to see something CCS is inline :-(
// DOTO: fix this crap

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-stacking .noUi-handle {
  /* This class is applied to the lower origin when
     its values is > 50%. */
  z-index: 10;
}

.noUi-stacking + .noUi-origin {
  /* Fix stacking order in IE7, which incorrectly
     creates a new context for the origins. */
  *z-index: -1;
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px;
}

.noUi-horizontal.noUi-extended {
  padding: 0 15px;
}

.noUi-horizontal.noUi-extended .noUi-origin {
  right: -15px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

.noUi-vertical.noUi-extended {
  padding: 15px 0;
}

.noUi-vertical.noUi-extended .noUi-origin {
  bottom: -15px;
}


.agb {
  color: #337ab7;
  text-decoration: none;

}
.agb:hover,
.agb:focus {
  color: #23527c;
  text-decoration: underline;
}
.agb:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.agb:visited {
  color: #23527c;
  background-color: #ffffff;
  text-decoration: underline;
}

.glyphicon.spinning {
    animation: spin 1s infinite linear;
    -webkit-animation: spin2 1s infinite linear;
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg);}
    to { transform: scale(1) rotate(360deg);}
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg);}
    to { -webkit-transform: rotate(360deg);}
}

/* Styling;
 */
.noUi-background {
  background: #FAFAFA;
  box-shadow: inset 0 1px 1px #f0f0f0;
}

.noUi-connect {
  background: #3FB8AF;
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-origin {
  border-radius: 2px;
}

.noUi-target {
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.noUi-target.noUi-connect {
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45), 0 3px 6px -5px #BBB;
}

/* Handles and cursors;
 */
.noUi-dragable {
  cursor: w-resize;
}

.noUi-vertical .noUi-dragable {
  cursor: n-resize;
}

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF,
  inset 0 1px 7px #EBEBEB,
  0 3px 6px -3px #BBB;
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF,
  inset 0 1px 7px #DDD,
  0 3px 6px -3px #BBB;
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled] .noUi-handle {
  cursor: not-allowed;
}

@media screen and (min-width: 1920px) {
  .module-content {
    width: 100%;
    height: 825px;
  }
}

@media screen and (max-width: 1440px) {
  .module-content {
    width: 100%;
    height: 580px;
  }
}

.switch input {
    display: none;
}
.switch i {
    display: inline-block;
    cursor: pointer;
    padding-right: 20px;
    transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    border-radius: 20px;
    box-shadow: inset 0 0 1px rgba(0,0,0,.5);
}
.switch i:before {
    display: block;
    content: '';
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: white;
    box-shadow: 0 1px 2px rgba(0,0,0,.5);
}
.switch :checked + i {
    padding-right: 0;
    padding-left: 25px;
    box-shadow: inset 0 0 1px rgba(0,0,0,.5), inset 0 0 40px #1AB394;
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,.5), inset 0 0 40px #1AB394;
}

.switch.red :checked + i {
    box-shadow: inset 0 0 1px rgba(0,0,0,.5), inset 0 0 40px red;
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,.5), inset 0 0 40px red;
}




