@bro-font-path: "assets/fonts";

// only woff needed for actual webbrowsers !
@font-face {
  font-family: 'Open Sans';
  src: url('@{bro-font-path}/OpenSans-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


html,
body {
  // background-color: #ff00ff; // hintergrund und seitenmenu : anpassen
  padding: 0;
  margin: 0;
  height: 100%;
  font-family: "Open Sans";
  font-size: 0.925rem;
  line-height: 1.1;
  font-weight: 400;
  font-style: normal;
}
html,
body,
* {
  box-sizing: border-box;
}

a:hover,
a:active,
.tile:active {
  outline: 0;
  background-color: #667899;
  border-color: #667899;
  color: #00b3ee;
}

a:visited {
  color: #667899;
}
a {
  color: #667899;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: #667899;
}

///////////////////////////////////////////
//
// modules css -> overrides the inpinia design
//
.form-group {
  vertical-align: middle;
}
.form-horizontal .control-label {
  vertical-align: middle;
  text-align: left;

}
.onoffswitch {
  margin-top: 5px;
}
.navbar {
  margin-bottom: 5px;
}
.navbar-collapse {
  margin: 1px;
}

.noUi-target {
  margin-top: 10px;
}




.btn {
  border-radius: 10px;
}

.btn-primary {
  background-color: #667899;
  border-color: #667899;
  border-radius: 10px;
}
.btn-primaryCorrection {
  margin-right: -10px !important;
  margin-left: 10px !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary.active:hover, .btn-primary.active:focus {
  background-color: #667899;
  border-color: #667899;
  border-radius: 10px;
  // margin-right: 10px;
  // border: 1px solid #00FF00;
}

.btn-success {
  background-color: #99241d;
}

.btn-large {
  // border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.btn-danger {
  background-color: #a02200;
  border-color: #a02200;
  color: #FFFFFF;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger, .btn-danger:active:focus, .btn-danger:active:hover, .btn-danger.active:hover, .btn-danger.active:focus
{
  background-color: #d54620;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}


  .label-primary {
    background-color: #ffffff;
    color: #667899;
    border-radius: 5px;
    margin-top: 5px;
  }



  .badge-primary {
    background-color: #ffffff;
    color: #667899;

  }



  .ibox-bro {
    padding: 2px;
    padding-bottom: 4px;
  }

  .ibox-bro-baseline { //extends ibox-bro, if we have a baseline
    padding-bottom: 22px;

  }

  .ibox {
    border-radius: 15px;
    border-color: #ffffff;
    float: none;
    background-color: #667899;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;
    margin-bottom: 22px;
  }

  .ibox-content {
    padding-bottom: 4px;
    border-radius: 10px;
    margin: 5px;
    margin-right: 4px; // right space for close button
    margin-bottom: 2px; // bottom space must be 0 for nose
    background-color: #ffffff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;
    border: 0px solid #FF0000;
  }

  .ibox-content-nose {
    padding-bottom: 0px;
  }

  .ibox-with-closeIcon {
    margin-right: 40px;
  }

  .ibox-group {
    padding-bottom: 20px;
    border: 0px solid #FF0000;
  }

  .ibox-bro-nose {
    height: 20px;
    text-align: right;
    padding-bottom: 0px;
    bottom: 0px;
    right: 20px;
    margin-right: 12px;
    fill: #667899 !important;
  }

  .ibox-close {
    right: 25px;
    top: 5px;
    width: 30px;
    height: 0px;
    font-size: 1.5rem;
    float: right;
    color: #ffffff;
    border: 0px solid #FF0000;
    margin-right: 5px;
  }

  .ibox-title {
    border: 0px solid #FF0000;
    text-align: right;
    float: right;
    color: #00ffff;
    position: absolute;
    bottom: 0px;
    right: 20px;
    // padding: .425rem .25rem;
    padding: 0px;
    // z-index: 999;
    background-color: #667899;
    min-height: 5px;
  }

  .ibox-title-close {
    right: 50px;
  }

  .ibox-title {
    h5 {
      color: #ededed;
      margin-bottom: 5px;
    }
  }



  //////////////////////////////////////////
  //
  // Dashboard css
  //

  .bro-header {
    width: 99%;
    float: left;
    display: block;
    position: relative;
  }
  .tile-area-controls {
    width: 100%;
    padding-right: 10px;
    float: right;
    text-align: right;
  }
  .tile-area-logo {
    padding: 10px 10px 10px 80px;
  }
  .tile, .tile-small, .tile-sqaure, .tile-wide, .tile-large, .tile-big, .tile-super {
    // opacity: 1;
    // -webkit-transform: scale(.8);
    // transform: scale(.8);
  }

  .bg-blue {
    background: #667899 !important;
  }
  .bg-yellow {
    background: #ffc933 !important;
  }
  .tile-area .tile-area-title {
    min-width: 100%;
    height: 100%;
    position: relative;
    padding-top: 120px;
    overflow: hidden;
  }
  .tile-header {
    border: 1px solid #999999;
    margin-left: 80px;
    min-width: 80px;
    width: auto;
    float: left;
    display: block;
    padding-top: 40px;
    position: relative;
  }
  .tile-label {
    text-align: right;
    float: right;
    color: #ffffff;
    font-size: 18px;
    position: absolute;
    bottom: 0;
    right: .625rem;
    padding: .425rem .25rem;
    z-index: 999;
  }
  .tile-group {
    border: 1px solid #999999;
    margin-left: 80px;
    min-width: 80px;
    width: auto;
    float: left;
    display: block;
    padding-top: 40px;
    position: relative;
  }
  .tile-group.one {
    width: 160px;
  }
  .tile-group.two,
  .tile-group.double {
    border: 0;
    width: 440px;
  }
  .tile-group .tile-group-title {
    color: #667899;
    font-size: 22px;
    line-height: 20px;
    position: absolute;
    top: 10px;
    left: 0;
  }
  .tile-container {
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    padding: 0;
    border: 0px solid #999999;
  }
  .tile-container:before,
  .tile-container:after {
    display: table;
    content: "";
  }
  .tile-container:after {
    clear: both;
  }
  .tile {
    // border: 4px solid #999999;
    border-radius: 10px;
    width: 205px;
    height: 205px;
    display: block;
    float: left;
    margin: 5px;
    background-color: #667899;
    box-shadow: inset 0 0 1px #FFFFCC;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  }


  .tile-box {
    width: 180px;
    height: 160px;
    border-radius: 10px;
    display: block;
    float: left;
    margin: 10px;
    background-color: #ffffff;
    box-shadow: inset 0 0 1px #FFFFCC;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;
    border: 1px solid #000000;

  }

  .tile-content {
    height: 110px;
    width: 160px;
    margin: 10px;
    overflow:auto;
    // border: 1px solid #000000;
  }

  .tile:hover {
    outline: 0;
  }
  .tile:active {
    outline: 0;
  }
  .tile.no-outline {
    outline-color: transparent;
  }

  .tile-wide {
    width: 420px;
    height: 205px;
    border-radius: 10px;
    display: block;
    float: left;
    margin: 5px;
    background-color: #667899;
    box-shadow: inset 0 0 1px #FFFFCC;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;

  }

  .tile-wide-box {
    width: 400px;
    height: 160px;
    border-radius: 10px;
    display: block;
    float: left;
    margin: 10px;
    background-color: #ffffff;
    box-shadow: inset 0 0 1px #FFFFCC;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;
    // border: 1px solid #000000;
  }

  .tile-wide-content {
    height: 120px;
    margin: 10px;
    overflow:auto;
    // border: 1px solid #000000;
  }


  .tile-textbox {
    width: 205px;
    height: 205px;
    border-radius: 10px;
    display: block;
    float: left;
    margin: 5px;
    background-color: #667899;
    box-shadow: inset 0 0 1px #FFFFCC;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;

  }

  .tile-textbox-box {
    width: 180px;
    height: 160px;
    border-radius: 10px;
    display: block;
    float: left;
    margin: 10px;
    background-color: #ffffff;
    box-shadow: inset 0 0 1px #FFFFCC;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;
    // border: 1px solid #000000;
  }

  .tile-textbox-content {
    height: 120px;
    margin: 10px;
    overflow:auto;
    // border: 1px solid #000000;
  }

  .tile-wide:hover {
    outline: 0;
  }
  .tile-wide:active {
    outline: 0;
  }
  .tile-wide.no-outline {
    outline-color: transparent;
  }


  .tile-large {
    width: 420px;
    height: 420px;
    border-radius: 10px;
    display: block;
    float: left;
    margin: 5px;
    background-color: #667899;
    box-shadow: inset 0 0 1px #FFFFCC;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;

  }

  .tile-large-box {
    width: 400px;
    height: 370px;
    border-radius: 10px;
    display: block;
    float: left;
    margin: 10px;
    background-color: #ffffff;
    box-shadow: inset 0 0 1px #FFFFCC;
    cursor: pointer;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: visible;
    //border: 1px solid #000000;
  }



  .tile-large-content {
    height: 330px;
    margin: 10px;
    overflow:auto;
    // border: 1px solid #000000;
  }

  .tile-large:hover {
    outline: 0;
  }

  .tile-nose {
    float: right;
    position: relative;
    bottom: 0px;
    right: 20px;
    margin-right: 12px;
    fill: #667899 !important;
  }

  .nose-yellow{
    fill: #ffc72c !important;
  }

  .square-button {
    height: 2.125rem;
    background-color: #ffffff;
    border: 1px #d9d9d9 solid;
    color: #262626;
    cursor: pointer;
    display: inline-block;
    outline: none;
    font-size: .875rem;
    line-height: 1;
    margin: .15625rem 0;
    position: relative;
    width: 2.125rem;
    min-width: 2.125rem;
    padding: 0 ;
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
  }

  .broBorder {
    border: 5px #667899 solid;
    border-radius: 10px;
    background-color: #667899;
  }

  .broBorderOpen {
    border: 5px #ffc72c solid;
    border-radius: 10px;
    background-color: #ffc72c;
  }



  .menuPoint {
    text-transform: none;
    display: block;
    padding: 1px 11px 1px 1px;
    background-color: #667899;
    cursor: pointer;
    font-weight: normal;
    color: #ffffff;
    position: relative;
    border: 1px #667899 solid;
    overflow: hidden;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s ease;
    text-align: left;
    font-size: 18px;
  }

  .tile-ico {
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 8px;
  }

  /*
  [class^="bro-"],
  [class*=" bro-"] {
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 8px;
  }
  */

  [class^="bro-"]:before,
  [class*=" bro-"]:before {
  }


  .bro-solid:before {
    content: url("/assets/icons/ico_solid.svg");
  }
  .bro-gapper:before {
    content: url("/assets/icons/ico_gapper.svg");
  }

  .bro-inflater:before {
    content: url("./assets/icons/ico_inflater.svg");
  }

  .bro-yielder:before {
    content: url("./assets/icons/ico_yielder.svg");
  }
  .bro-reinvester:before {
    content: url("./assets/icons/ico_reinvester.svg");
  }

  .bro-optinvester:before {
    content: url("./assets/icons/ico_optinvester.svg");
  }

  .bro-customer:before {
    content: url("./assets/icons/ico_customer.svg");
  }

  .bro-config:before {
    content: url("./assets/icons/ico_config.svg");
  }


  /*
  .bro-logo:before {
    content: url("./assets/icons/logo_420x205.png");
  }
  */
  [class*=tile-transform-] {
    transition: all 0.22s ease;
  }
  .tile.tile-transform-top {
    -webkit-transform: perspective(500px) rotateX(0.138372rad) !important;
    transform: perspective(500px) rotateX(0.138372rad) !important;
  }

  .tile-area-scheme-ori[class*=tile] {
    background-color: #667899;
  }
  .tile-area-scheme-green[class*=tile] {
    background-color: #128023;
  }
  .tile-area-scheme-fb[class*=tile] {
    background-color: #454545;
  }


  input.passwd:disabled {
    background-color: transparent;
    background: url('./assets/vectorData/hash.svg') repeat center;
    // background: url('./assets/vectorData/caution-stripe-bw.svg') repeat center;
  }

  input.passwd::placeholder{
    //color: white;
  }

  input.passwd{
    background-color: white;
  }




  @plusHeight: calc(~'100% + 4px');

  .keyicon {
    background-color: transparent;
    background: url('./assets/vectorData/caution-key-bw.svg') no-repeat right;
    background-size: contain;
    //height: @fullHeight;
    vertical-align: top;
    padding-right: 34px;
    margin-right: -34px
  }

  input.passwd, .lockicon {
    background-color: transparent;
    background: url('./assets/vectorData/caution-lock-bw.svg') no-repeat right;
    background-size: contain;
    //@height: @fullHeight;
    vertical-align: top;
    padding-right: 34px;
    margin-right: -34px
  }


  #diagonalHatch {
    stroke: #0000ff;
    fill: red;
  }

  .padCalc{
    padding-top: 5px;
  }


  .formRealEstate, .formInvestment, .formSpending {
    position: absolute; width: 550px; top: 0px; right: 0px;
  }