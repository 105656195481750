@import "variables";

// only woff needed for actual webbrowsers !
@font-face {
  font-family: 'bro';
  src: url('@{bro-font-path}/bro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

i.bro-icon {
  font-family: 'bro' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bro-icon-2x {
  font-size: 2em;
}

.bro-icon-3x {
  font-size: 3em;
}

.bro-icon-4x {
  font-size: 4em;
}

.icon-gapper {
  &:before {
    content: @icon-gapper;
  }
}
.icon-reinvester {
  &:before {
    content: @icon-reinvester;
  }
}

