/* Calculator plugin
----------------------------------- */
.calculator {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(assets/static/calc_off.png) no-repeat;
  border: none;
  cursor: pointer;
}

.calculator.checked {
  background: url(assets/static/calc_on.png) no-repeat;
}

.calculator.disabled {
  background: url(assets/static/calc_off.png) no-repeat;
  cursor: default;
}

.calculator.checked.disabled {
  background: url(assets/static/calc_off_over.png) no-repeat;
}

/* Lock plugin
----------------------------------- */
.lock {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 14px;
  height: 14px;
  background: url(assets/static/fa-unlock.png) no-repeat;
  border: none;
  cursor: pointer;
}

.lock.checked {
  background: url(assets/static/fa-lock.png) no-repeat;
}

.lock.disabled {
  background: url(assets/static/fa-unlock.png) no-repeat;
  cursor: default;
}

.lock.checked.disabled {
  background: url(assets/static/fa-unlock.png) no-repeat;
}

/* Expand LeftRight plugin
----------------------------------- */

.expandLeftRight {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(assets/static/expBtn_right.png) no-repeat;
  border: none;
  cursor: pointer;
}

.expandLeftRight.checked {
  background: url(assets/static/expBtn_left.png) no-repeat;
}
.expandLeftRight.disabled {
  background: url(assets/static/expBtn_right.png) no-repeat;
  cursor: default;
}
.expandLeftRight.checked.disabled {
  background: url(assets/static/expBtn_left.png) no-repeat;
}

/* Expand UpDown plugin
----------------------------------- */

.expandUpDown {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0px;
  width: 28px;
  height: 28px;
  background: url(assets/static/expBtn_down.png) no-repeat;
  border: none;
  cursor: pointer;
}

.expandUpDown.checked {
  background: url(assets/static/expBtn_up.png) no-repeat;
}
.expandUpDown.disabled {
  background: url(assets/static/expBtn_down.png) no-repeat;
  cursor: default;
}
.expandUpDown.checked.disabled {
  background: url(assets/static/expBtn_up.png) no-repeat;
}