// Color variables (appears count calculates by raw css)
@color0: #95bc87; // Appears 6 times
@color1: #87aebc; // Appears 3 times
@color2: #b8bc87; // Appears 3 times
@color3: #9bd0e2; // Appears 3 times
@color4: #87bcaf; // Appears 3 times

@color5: #95bc87; // Appears 3 times
@color6: #87aebc; // Appears 3 times
@color7: #b8bc87; // Appears 3 times
@color8: #9bd0e2; // Appears 3 times
@color9: #87aebc; // Appears 3 times

@color10: #87bcaf; // Appears 3 times
@color11: #87aebc; // Appears 3 times
@color12: #b8bc87; // Appears 3 times
@color13: #9bd0e2; // Appears 3 times

// Width variables (appears count calculates by raw css)
@width0: 100%; // Appears 17 times
@width1: 0; // Appears 17 times

// Height variables (appears count calculates by raw css)
@height0: 0; // Appears 17 times

.ct-double-octave:after,.ct-major-eleventh:after,.ct-major-second:after,.ct-major-seventh:after,.ct-major-sixth:after,.ct-major-tenth:after,.ct-major-third:after,.ct-major-twelfth:after,.ct-minor-second:after,.ct-minor-seventh:after,.ct-minor-sixth:after,.ct-minor-third:after,.ct-octave:after,.ct-perfect-fifth:after,.ct-perfect-fourth:after,.ct-square:after {
  clear:both;
  content:"";
}
.ct-label {
  color:rgba(0,0,0,.4);
  fill:rgba(0,0,0,.4);
  font-size: 0.2rem;
  line-height:1;
}
.ct-chart-bar {
  .ct-label {
    display:-moz-box;
    display:-ms-flexbox;
    display:-webkit-box;
    display:-webkit-flex;
    display:block;
    display:flex;
  }
  .ct-label.ct-horizontal.ct-start {
    -ms-flex-align:flex-end;
    -ms-flex-pack:center;
    -webkit-align-items:flex-end;
    -webkit-box-align:flex-end;
    -webkit-box-pack:center;
    -webkit-justify-content:center;
    align-items:flex-end;
    justify-content:center;
    text-align:center;
    text-anchor:start;
  }
  .ct-label.ct-horizontal.ct-end {
    -ms-flex-align:flex-start;
    -ms-flex-pack:center;
    -webkit-align-items:flex-start;
    -webkit-box-align:flex-start;
    -webkit-box-pack:center;
    -webkit-justify-content:center;
    align-items:flex-start;
    justify-content:center;
    text-align:center;
    text-anchor:start;
  }
}
.ct-chart-line {
  .ct-label {
    display:-moz-box;
    display:-ms-flexbox;
    display:-webkit-box;
    display:-webkit-flex;
    display:block;
    display:flex;
  }
}
.ct-label.ct-horizontal.ct-start {
  -ms-flex-align:flex-end;
  -ms-flex-pack:flex-start;
  -webkit-align-items:flex-end;
  -webkit-box-align:flex-end;
  -webkit-box-pack:flex-start;
  -webkit-justify-content:flex-start;
  align-items:flex-end;
  justify-content:flex-start;
  text-align:left;
  text-anchor:start;
}
.ct-label.ct-horizontal.ct-end {
  -ms-flex-align:flex-start;
  -ms-flex-pack:flex-start;
  -webkit-align-items:flex-start;
  -webkit-box-align:flex-start;
  -webkit-box-pack:flex-start;
  -webkit-justify-content:flex-start;
  align-items:flex-start;
  justify-content:flex-start;
  text-align:left;
  text-anchor:start;
}
.ct-label.ct-vertical.ct-start {
  -ms-flex-align:flex-end;
  -ms-flex-pack:flex-end;
  -webkit-align-items:flex-end;
  -webkit-box-align:flex-end;
  -webkit-box-pack:flex-end;
  -webkit-justify-content:flex-end;
  align-items:flex-end;
  justify-content:flex-end;
  text-align:right;
  text-anchor:end;
}
.ct-label.ct-vertical.ct-end {
  -ms-flex-align:flex-end;
  -ms-flex-pack:flex-start;
  -webkit-align-items:flex-end;
  -webkit-box-align:flex-end;
  -webkit-box-pack:flex-start;
  -webkit-justify-content:flex-start;
  align-items:flex-end;
  justify-content:flex-start;
  text-align:left;
  text-anchor:start;
}
.ct-chart-bar.ct-horizontal-bars {
  .ct-label.ct-horizontal.ct-start {
    -ms-flex-align:flex-end;
    -ms-flex-pack:flex-start;
    -webkit-align-items:flex-end;
    -webkit-box-align:flex-end;
    -webkit-box-pack:flex-start;
    -webkit-justify-content:flex-start;
    align-items:flex-end;
    justify-content:flex-start;
    text-align:left;
    text-anchor:start;
  }
  .ct-label.ct-horizontal.ct-end {
    -ms-flex-align:flex-start;
    -ms-flex-pack:flex-start;
    -webkit-align-items:flex-start;
    -webkit-box-align:flex-start;
    -webkit-box-pack:flex-start;
    -webkit-justify-content:flex-start;
    align-items:flex-start;
    justify-content:flex-start;
    text-align:left;
    text-anchor:start;
  }
  .ct-label.ct-vertical.ct-start {
    -ms-flex-align:center;
    -ms-flex-pack:flex-end;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -webkit-box-pack:flex-end;
    -webkit-justify-content:flex-end;
    align-items:center;
    justify-content:flex-end;
    text-align:right;
    text-anchor:end;
  }
  .ct-label.ct-vertical.ct-end {
    -ms-flex-align:center;
    -ms-flex-pack:flex-start;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -webkit-box-pack:flex-start;
    -webkit-justify-content:flex-start;
    align-items:center;
    justify-content:flex-start;
    text-align:left;
    text-anchor:end;
  }
}
.ct-grid {
  stroke-dasharray:2px;
  stroke-width:1px;
  stroke:rgba(0,0,0,.2);
}
.ct-point {
  stroke-linecap:round;
  stroke-width:1px;
}
.ct-line {
  fill:none;
  stroke-width:4px;
}
.ct-area {
  fill-opacity:.1;
  stroke:none;
}
.ct-bar {
  fill:none;
  stroke-width:10px;
}
.ct-slice-donut {
  fill:none;
  stroke-width:6px;
}
.ct-series-a .ct-bar,.ct-series-a .ct-line,.ct-series-a .ct-point,.ct-series-a .ct-slice-donut {
  stroke:@color12;
}
.ct-series-a {
  .ct-area {
    fill:@color12;
  }
  .ct-slice-pie {
    fill:@color12;
  }
}
.ct-series-b .ct-bar,.ct-series-b .ct-line,.ct-series-b .ct-point,.ct-series-b .ct-slice-donut {
  stroke:@color0;
}
.ct-series-b {
  .ct-area {
    fill:@color0;
  }
  .ct-slice-pie {
    fill:@color0;
  }
}
.ct-series-c .ct-bar,.ct-series-c .ct-line,.ct-series-c .ct-point,.ct-series-c .ct-slice-donut {
  stroke:@color9;
}
.ct-series-c {
  .ct-area {
    fill:@color9;
  }
  .ct-slice-pie {
    fill:@color9;
  }
}
.ct-series-d .ct-bar,.ct-series-d .ct-line,.ct-series-d .ct-point,.ct-series-d .ct-slice-donut {
  stroke:@color8;
}
.ct-series-d {
  .ct-area {
    fill:@color8;
  }
  .ct-slice-pie {
    fill:@color8;
  }
}
.ct-series-e .ct-bar,.ct-series-e .ct-line,.ct-series-e .ct-point,.ct-series-e .ct-slice-donut {
  stroke:@color10;
}
.ct-series-e {
  .ct-area {
    fill:@color10;
  }
  .ct-slice-pie {
    fill:@color10;
  }
}
.ct-series-f .ct-bar,.ct-series-f .ct-line,.ct-series-f .ct-point,.ct-series-f .ct-slice-donut {
  stroke:@color11;
}
.ct-series-f {
  .ct-area {
    fill:@color11;
  }
  .ct-slice-pie {
    fill:@color11;
  }
}
.ct-series-g .ct-bar,.ct-series-g .ct-line,.ct-series-g .ct-point,.ct-series-g .ct-slice-donut {
  stroke:@color7;
}
.ct-series-g {
  .ct-area {
    fill:@color7;
  }
  .ct-slice-pie {
    fill:@color7;
  }
}
.ct-series-h .ct-bar,.ct-series-h .ct-line,.ct-series-h .ct-point,.ct-series-h .ct-slice-donut {
  stroke:@color13;
}
.ct-series-h {
  .ct-area {
    fill:@color13;
  }
  .ct-slice-pie {
    fill:@color13;
  }
}
.ct-series-i .ct-bar,.ct-series-i .ct-line,.ct-series-i .ct-point,.ct-series-i .ct-slice-donut {
  stroke:@color0;
}
.ct-series-i {
  .ct-area {
    fill:@color0;
  }
  .ct-slice-pie {
    fill:@color0;
  }
}
.ct-series-j .ct-bar,.ct-series-j .ct-line,.ct-series-j .ct-point,.ct-series-j .ct-slice-donut {
  stroke:@color6;
}
.ct-series-j {
  .ct-area {
    fill:@color6;
  }
  .ct-slice-pie {
    fill:@color6;
  }
}
.ct-series-k .ct-bar,.ct-series-k .ct-line,.ct-series-k .ct-point,.ct-series-k .ct-slice-donut {
  stroke:@color2;
}
.ct-series-k {
  .ct-area {
    fill:@color2;
  }
  .ct-slice-pie {
    fill:@color2;
  }
}
.ct-series-l .ct-bar,.ct-series-l .ct-line,.ct-series-l .ct-point,.ct-series-l .ct-slice-donut {
  stroke:@color1;
}
.ct-series-l {
  .ct-area {
    fill:@color1;
  }
  .ct-slice-pie {
    fill:@color1;
  }
}
.ct-series-m .ct-bar,.ct-series-m .ct-line,.ct-series-m .ct-point,.ct-series-m .ct-slice-donut {
  stroke:@color3;
}
.ct-series-m {
  .ct-area {
    fill:@color3;
  }
  .ct-slice-pie {
    fill:@color3;
  }
}
.ct-series-n .ct-bar,.ct-series-n .ct-line,.ct-series-n .ct-point,.ct-series-n .ct-slice-donut {
  stroke:@color4;
}
.ct-series-n {
  .ct-area {
    fill:@color4;
  }
  .ct-slice-pie {
    fill:@color4;
  }
}
.ct-series-o .ct-bar,.ct-series-o .ct-line,.ct-series-o .ct-point,.ct-series-o .ct-slice-donut {
  stroke:@color5;
}
.ct-series-o {
  .ct-area {
    fill:@color5;
  }
  .ct-slice-pie {
    fill:@color5;
  }
}
.ct-square {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:100%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-square>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-minor-second {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:93.75%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-minor-second>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-major-second {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:88.8888888889%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-major-second>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-minor-third {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:83.3333333333%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-minor-third>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-major-third {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:80%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-major-third>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-perfect-fourth {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:75%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-perfect-fourth>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-perfect-fifth {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:66.6666666667%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-perfect-fifth>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-minor-sixth {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:62.5%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-minor-sixth>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-golden-section {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:61.804697157%;
    width:@width1;
  }
  &:after {
    clear:both;
    content:"";
    display:table;
  }
}
.ct-golden-section>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-major-sixth {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:60%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-major-sixth>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-minor-seventh {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:56.25%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-minor-seventh>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-major-seventh {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:53.3333333333%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-major-seventh>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-octave {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:50%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-octave>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-major-tenth {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:40%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-major-tenth>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-major-eleventh {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:37.5%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-major-eleventh>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-major-twelfth {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:33.3333333333%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-major-twelfth>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}
.ct-double-octave {
  display:block;
  position:relative;
  width:@width0;
  &:before {
    content:"";
    display:block;
    float:left;
    height:@height0;
    padding-bottom:25%;
    width:@width1;
  }
  &:after {
    display:table;
  }
}
.ct-double-octave>svg {
  display:block;
  left:0;
  position:absolute;
  top:0;
}