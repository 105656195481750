/*
 * WIZARDS
 */

// BOOTSTRAP WIZARD
// -------------------------

@wizard-step-complete: #0AA66E;
@wizard-step-active: #006c8c;
@white: #ffffff;
@gray-mid-light: lighten(@black, 75%);
@black: #000;
@green: #356e35;

.bootstrapWizard {
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
}

.bootstrapWizard a:hover, .bootstrapWizard a:active, .bootstrapWizard a:focus {
  text-decoration: none;
  background: @white;
}

.bootstrapWizard li {
  display: block;
  float: left;
  width: 25%;
  text-align: center;
  padding-left: 0;
}

.bootstrapWizard li:before {
  border-top: 3px solid #55606E;
  content: "";
  display: block;
  font-size: 0;
  overflow: hidden;
  position: relative;
  top: 11px;
  right: 1px;
  width: 100%;
  z-index: 1;
}

.bootstrapWizard li:first-child:before {
  left: 50%;
  max-width: 50%;
}

.bootstrapWizard li:last-child:before {
  max-width: 50%;
  width: 50%;
}

.bootstrapWizard li.complete .step {
  background: @wizard-step-complete;
  padding: 1px 6px;
  border: 3px solid #55606E;
}

.bootstrapWizard li .step i {
  font-size: 10px;
  font-weight: normal;
  position: relative;
  top: -1.5px;
}

.bootstrapWizard li .step {
  background: #B2B5B9;
  color: @white;
  display: inline;
  font-size: 15px;
  font-weight: bold;
  line-height: 12px;
  padding: 7px 13px;
  border: 3px solid transparent;
  border-radius: 50%;
  line-height: normal;
  position: relative;
  text-align: center;
  z-index: 2;
  transition: all 0.1s linear 0s;
}

.bootstrapWizard li.active .step, .bootstrapWizard li.active.complete .step {
  background: @wizard-step-active;
  color: @white;
  font-weight: bold;
  padding: 7px 13px;
  font-size: 15px;
  border-radius: 50%;
  border: 3px solid #55606E;
}

.bootstrapWizard li.complete .title, .bootstrapWizard li.active .title {
  color: #2B3D53;
}

.bootstrapWizard li .title {
  color: @gray-mid-light;
  display: block;
  font-size: 13px;
  line-height: 15px;
  max-width: 100%;
  position: relative;
  table-layout: fixed;
  text-align: center;
  top: 20px;
  word-wrap: break-word;
  z-index: 104;
}

.wizard-actions {
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
}

.wizard-actions li {
  display: inline;
}

.tab-content.transparent {
  background-color: transparent;
}

/*
 * FUELUX WIZARD
 */

.fuelux .wizard {
  position: relative;
  overflow: hidden;
  background-color: #f9f9f9;
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  *zoom: 1;
  -webkit-box-shadow: 0 1px 4px rgba(red(@black), green(@black), blue(@black), 0.065);
  -moz-box-shadow: 0 1px 4px rgba(red(@black), green(@black), blue(@black), 0.065);
  box-shadow: 0 1px 4px rgba(red(@black), green(@black), blue(@black), 0.065);
}

.fuelux .wizard:before, .fuelux .wizard:after {
  display: table;
  line-height: 0;
  content: "";
}

.fuelux .wizard:after {
  clear: both;
}

.fuelux .wizard ul {
  width: 4000px;
  padding: 0;
  margin: 0;
  list-style: none outside none;
}

.fuelux .wizard ul.previous-disabled li.complete {
  cursor: default;
}

.fuelux .wizard ul.previous-disabled li.complete:hover {
  color: @green;
  cursor: default;
  background: #f3f4f5;
}

.fuelux .wizard ul.previous-disabled li.complete:hover .chevron:before {
  border-left-color: #f3f4f5;
}

.fuelux .wizard ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #999999;
  cursor: default;
  background: #ededed;
}

.fuelux .wizard ul li .chevron {
  position: absolute;
  top: 0;
  right: -14px;
  z-index: 1;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #d4d4d4;
}

.fuelux .wizard ul li .chevron:before {
  position: absolute;
  top: -24px;
  right: 1px;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #ededed;
  content: "";
}

.fuelux .wizard ul li.complete {
  color: @state-success-text;
  background: #f3f4f5;
}

.fuelux .wizard ul li.complete:hover {
  cursor: pointer;
  background: #e7eff8;
}

.fuelux .wizard ul li.complete:hover .chevron:before {
  border-left: 14px solid #e7eff8;
}

.fuelux .wizard ul li.complete .chevron:before {
  border-left: 14px solid #f3f4f5;
}

.fuelux .wizard ul li.active {
  color: #3a87ad;
  background: #f1f6fc;
}

.fuelux .wizard ul li.active .chevron:before {
  border-left: 14px solid #f1f6fc;
}

.fuelux .wizard ul li .badge {
  margin-right: 8px;
}

.fuelux .wizard ul li:first-child {
  padding-left: 20px;
  border-radius: 4px 0 0 4px;
}

.fuelux .wizard .actions {
  position: absolute;
  right: 0;
  z-index: 2;
  float: right;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 46px;
  vertical-align: middle;
  background-color: @gray-lighter;
  border-left: 1px solid #d4d4d4;
}

.fuelux .wizard .actions a {
  margin-right: 8px;
  font-size: 12px;
  line-height: 45px;
}

.fuelux .wizard .actions .btn-prev i {
  margin-right: 5px;
}

.fuelux .wizard .actions .btn-next i {
  margin-left: 5px;
}

.fuelux .step-content .step-pane {
  display: none;
}

.fuelux .step-content .active {
  display: block;
}

.fuelux .step-content .active .btn-group .active {
  display: inline-block;
}