/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.3
 *
*/

// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");

// Variables, Mixins
@import "variables.less";
@import "mixins.less";

// INSPINIA Theme Elements
@import "typography.less";
@import "navigation.less";
@import "top_navigation.less";
@import "buttons.less";
@import "badgets_labels.less";
@import "elements.less";
@import "sidebar.less";
@import "base.less";
@import "pages.less";
@import "chat.less";
@import "metismenu.less";
@import "spinners.less";

// Landing page styles
@import "landing.less";

// RTL Support
@import "rtl.less";

// For demo only - config box style
@import "theme-config.less";

// INSPINIA Skins
@import "skins.less";
@import "md-skin.less";

// Media query style
@import "media.less";

// Custom style
// Your custom style to override base style
@import "custom.less";













